define("discourse/plugins/discourse-solved/discourse/connectors/bread-crumbs-right/solved-status-filter", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse-common/lib/get-owner", "I18n"], function (_exports, _component, _object, _service, _getOwner, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const QUERY_PARAM_VALUES = {
    solved: "yes",
    unsolved: "no",
    all: null
  };
  const UX_VALUES = {
    yes: "solved",
    no: "unsolved"
  };
  let SolvedStatusFilter = _exports.default = (_class = class SolvedStatusFilter extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
    }
    static shouldRender(args, helper) {
      const router = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:router");
      if (!helper.siteSettings.show_filter_by_solved_status || router.currentRouteName === "discovery.categories" || args.editingCategory) {
        return false;
      } else if (helper.siteSettings.allow_solved_on_all_topics) {
        return true;
      } else {
        return args.currentCategory?.enable_accepted_answers;
      }
    }
    get statuses() {
      return ["all", "solved", "unsolved"].map(status => {
        return {
          name: _I18n.default.t(`solved.topic_status_filter.${status}`),
          value: status
        };
      });
    }
    get status() {
      const queryParamValue = this.router.currentRoute.attributes?.modelParams?.solved;
      return UX_VALUES[queryParamValue] || "all";
    }
    changeStatus(newStatus) {
      this.router.transitionTo({
        queryParams: {
          solved: QUERY_PARAM_VALUES[newStatus]
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "changeStatus", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeStatus"), _class.prototype)), _class);
});